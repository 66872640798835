<template>
    <div
        class="modal top-24 backdrop-blur-sm"
        tabindex="-1"
        id="uploadPrescriptionModal"
        aria-labelledby="uploadPrescriptionModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title f-16 clrBlueDark pt-1 pb-2">
                        Upload Prescription
                    </h3>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <form action="">
                        <div class="row">
                            <div class="col form-group">
                                <n-space
                                    vertical
                                    :class="[
                                        vd.name.$error
                                            ? 'is-invalid-parent'
                                            : '',
                                        !vd.name.$invalid
                                            ? 'is-valid-parent'
                                            : '',
                                    ]"
                                >
                                    <n-input
                                        v-model:value="state.name"
                                        placeholder="Name*"
                                        :theme-overrides="inputThemeOverrides"
                                        :clearable="true"
                                        :maxlength="
                                            vd.name.maxLength.$params.max
                                        "
                                        @blur="vd.name.$touch()"
                                        @input="vd.name.$touch()"
                                        :disabled="state.submitting"
                                        :loading="state.submitting"
                                        :class="[
                                            vd.name.$error ? 'is-invalid' : '',
                                            !vd.name.$invalid ? 'is-valid' : '',
                                        ]"
                                    >
                                    </n-input>
                                </n-space>

                                <div
                                    v-if="vd.name.required.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Name is Required
                                </div>
                                <div
                                    v-else-if="vd.name.alpha.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Only Enter Valid Characters
                                </div>
                                <div
                                    v-else-if="vd.name.maxLength.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Name should be at max
                                    {{ vd.name.maxLength.$params.max }}
                                    digit
                                </div>
                            </div>

                            <div class="col form-group">
                                <n-space
                                    vertical
                                    :class="[
                                        vd.phone.$error
                                            ? 'is-invalid-parent'
                                            : '',
                                        !vd.phone.$invalid
                                            ? 'is-valid-parent'
                                            : '',
                                    ]"
                                >
                                    <n-input
                                        v-model:value="state.phone"
                                        placeholder="Phone Number*"
                                        :theme-overrides="inputThemeOverrides"
                                        :clearable="true"
                                        :disabled="state.submitting"
                                        :loading="state.submitting"
                                        :maxlength="
                                            vd.phone.maxLength.$params.max
                                        "
                                        @blur="vd.phone.$touch()"
                                        @input="vd.phone.$touch()"
                                        :class="[
                                            vd.phone.$error ? 'is-invalid' : '',
                                            !vd.phone.$invalid
                                                ? 'is-valid'
                                                : '',
                                        ]"
                                    >
                                    </n-input>
                                </n-space>

                                <div
                                    v-if="vd.phone.required.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Phone is Required
                                </div>
                                <div
                                    v-else-if="vd.phone.number.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Only Enter Valid Characters
                                </div>
                                <div
                                    v-else-if="vd.phone.maxLength.$invalid"
                                    class="invalid-feedback font-weight-semi-bold"
                                >
                                    Phone should be at max
                                    {{ vd.phone.maxLength.$params.max }}
                                    digit
                                </div>
                            </div>
                        </div>

                        <div
                            class="form-group"
                            v-if="props.query && props.query.Enabled"
                        >
                            <n-space
                                vertical
                                :class="[
                                    vd.query.$error ? 'is-invalid-parent' : '',
                                    !vd.query.$invalid ? 'is-valid-parent' : '',
                                ]"
                            >
                                <n-input
                                    v-model:value="state.query"
                                    :placeholder="props.query.Placeholder"
                                    type="textarea"
                                    :theme-overrides="inputThemeOverrides"
                                    :clearable="true"
                                    :disabled="state.submitting"
                                    :loading="state.submitting"
                                    :maxlength="vd.query.maxLength.$params.max"
                                    @blur="vd.query.$touch()"
                                    @input="vd.query.$touch()"
                                    :autosize="{
                                        minRows: 3,
                                        maxRows: 5,
                                    }"
                                    :class="[
                                        vd.query.$error ? 'is-invalid' : '',
                                        !vd.query.$invalid ? 'is-valid' : '',
                                    ]"
                                >
                                </n-input>
                            </n-space>

                            <div
                                v-if="vd.query.required.$invalid"
                                class="invalid-feedback font-weight-semi-bold"
                            >
                                Address is Required
                            </div>
                            <div
                                v-else-if="vd.query.maxLength.$invalid"
                                class="invalid-feedback font-weight-semi-bold"
                            >
                                Address should be at max
                                {{ vd.query.maxLength.$params.max }}
                                digit
                            </div>
                        </div>

                        <div
                            class="form-group"
                            v-if="
                                props.uploadPrescription &&
                                props.uploadPrescription.Enabled
                            "
                        >
                            <div
                                class="border-common booking-details-border bg-white px-0 py-0 px-md-3 py-md-3 mb-md-0 mb-3"
                            >
                                <div
                                    class="row"
                                    :class="[
                                        vd.prescriptions.$error
                                            ? 'is-invalid-parent'
                                            : '',
                                        !vd.prescriptions.$invalid
                                            ? 'is-valid-parent'
                                            : '',
                                    ]"
                                >
                                    <div class="col-12">
                                        <div
                                            class="border-common bg-white pointer pos-rel f-18 clrBlueDark text-center py-2"
                                            :class="[
                                                vd.prescriptions.$error
                                                    ? 'is-invalid'
                                                    : '',
                                                !vd.prescriptions.$invalid
                                                    ? 'is-valid'
                                                    : '',
                                            ]"
                                        >
                                            <input
                                                class="pos-abs pointer w-100 upload-presciption-input"
                                                type="file"
                                                @change="validatePrescription"
                                                accept=".pdf,.docx,.doc,.png,.jpeg,.jpg"
                                                :disabled="state.submitting"
                                                required
                                            />
                                            <img
                                                class="img-align mr-2"
                                                :src="asset(`img/upload.png`)"
                                            />
                                            {{
                                                props.uploadPrescription
                                                    .Placeholder
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <p class="f-14 l-16 f-sm-13 mt-1 mt-md-2 mb-0">
                                    Please attach a prescription in .jpg, .jpeg,
                                    .png or in .pdf format & max. file size
                                    allowed is 2 MB to proceed
                                </p>
                                <div
                                    v-if="vd.prescriptions.required.$invalid"
                                    class="invalid-feedback"
                                >
                                    Please Upload Your Prescription
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <n-space
                                vertical
                                :class="[
                                    vd.service.$error
                                        ? 'is-invalid-parent'
                                        : '',
                                    vd.service.$model &&
                                    vd.service.$dirty &&
                                    !vd.service.$invalid
                                        ? 'is-valid-parent'
                                        : '',
                                ]"
                                v-if="props.service && props.service.Enabled"
                            >
                                <n-select
                                    v-model:value="state.service"
                                    filterable
                                    :placeholder="props.service.Placeholder"
                                    :options="servicesMapped"
                                    :theme-overrides="{
                                        menuBoxShadow:
                                            '0 6px 16px -9px rgba(0, 0, 0, .08), 0 9px 28px 0 rgba(0, 0, 0, .05), 0 12px 48px 16px rgba(0, 0, 0, .03)',
                                        peers: {
                                            InternalSelection: {
                                                textColor: '#003873',
                                                placeholderColor: '#7f8686',
                                                heightMedium: '46px',
                                                fontSizeMedium: '17px',
                                            },
                                        },
                                    }"
                                    :class="[
                                        vd.service.$error ? 'is-invalid' : '',
                                        vd.service.$model &&
                                        vd.service.$dirty &&
                                        !vd.service.$invalid
                                            ? 'is-valid'
                                            : '',
                                    ]"
                                />
                            </n-space>
                            <div
                                v-if="vd.service.required.$invalid"
                                class="invalid-feedback font-weight-semi-bold"
                            >
                                Service is Required
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="mt-1 order-sm-2 pb-md-3">
                                    <button
                                        class="solid-btn bg-[#4DBDC0] f-23 f-sm-20 text-white w-100"
                                        :class="[
                                            vd.$error ? 'is-form-invalid' : '',
                                            !vd.$invalid ? 'is-form-valid' : '',
                                        ]"
                                        type="button"
                                        :disabled="
                                            state.submitting && !shouldSubmit
                                        "
                                        @click.prevent="submitFeedback"
                                    >
                                        <span
                                            class="spinner-border spinner-border-sm"
                                            :class="[
                                                !state.submitting ? 'hide' : '',
                                            ]"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            class="form-group mb-0"
                            v-if="
                                props.authorizeContact &&
                                props.authorizeContact.Enabled
                            "
                            :class="[
                                vd.authorizeContact.$error ? 'is-invalid' : '',
                            ]"
                        >
                            <n-space
                                verticle
                                class="p-2"
                                :class="[
                                    vd.authorizeContact.$error
                                        ? 'is-invalid-parent'
                                        : '',
                                ]"
                            >
                                <n-checkbox
                                    size="large"
                                    v-model:checked="state.authorizeContact"
                                    :disabled="state.submitting"
                                    ><n-p
                                        strong
                                        :theme-overrides="
                                            typographyThemeOverrides
                                        "
                                    >
                                        By clicking you agree to our
                                        <a href="/terms-and-conditions">T&Cs</a
                                        >. Get Updates on Whatsapp
                                    </n-p></n-checkbox
                                >
                            </n-space>
                            <div
                                v-if="vd.authorizeContact.required.$invalid"
                                class="invalid-feedback p-2"
                            >
                                Authorization is required
                            </div>
                        </div>

                        <div
                            class="form-group"
                            v-if="
                                props.consentWhatsapp &&
                                props.consentWhatsapp.Enabled
                            "
                            :class="[
                                vd.consentWhatsapp.$error ? 'is-invalid' : '',
                                vd.consentWhatsapp.$dirty &&
                                !vd.consentWhatsapp.$invalid
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <n-space
                                verticle
                                class="p-2"
                                :class="[
                                    vd.consentWhatsapp.$error
                                        ? 'is-invalid-parent'
                                        : '',
                                    vd.consentWhatsapp.$dirty &&
                                    !vd.consentWhatsapp.$invalid
                                        ? 'is-valid-parent'
                                        : '',
                                ]"
                            >
                                <n-checkbox
                                    size="large"
                                    v-model:checked="state.consentWhatsapp"
                                    :disabled="state.submitting"
                                    ><n-p
                                        strong
                                        :theme-overrides="
                                            typographyThemeOverrides
                                        "
                                    >
                                        {{
                                            props.consentWhatsapp.Placeholder
                                        }}</n-p
                                    ></n-checkbox
                                >
                            </n-space>
                            <div
                                v-if="vd.consentWhatsapp.required.$invalid"
                                class="invalid-feedback p-2"
                            >
                                Authorization is required
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export { default } from "./Controller";
</script>

<style lang="scss" scoped>
p {
    white-space: pre-line;
}

[disabled="disabled"] {
    color: #919798;
}
a[disabled="disabled"] {
    cursor: not-allowed;
}

button[disabled] {
    background: #46767d;
    color: #f4f4f4;
    border-radius: 0.3 rem;
    cursor: not-allowed;
    opacity: 0.65;
}
.spinner-border {
    vertical-align: baseline;
    &.hide {
        display: none;
    }
}

.is-form-invalid {
    background: #b6355f;
    color: #fff;
}

.is-form-invalid:hover {
    background: #db4275;
    color: #fff;
}

.is-valid {
    background-color: #28a74515 !important;
    border: 1px solid #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --border-hover: 1px solid #28a745 !important;
    --border-focus: 1px solid #28a745 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 10%);
    }
}
.is-valid-btn {
    background-color: #28a74515 !important;
    border: 1px solid #28a745;
    --border-hover: 1px solid #28a745 !important;
    --border-focus: 1px solid #28a745 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 10%);
    }
}
.is-invalid {
    background-color: #dc354515 !important;
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --border-hover: 1px solid #dc3545 !important;
    --border-focus: 1px solid #dc3545 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 10%);
    }
}
.is-invalid-btn {
    background-color: #dc354515 !important;
    border: 1px solid #dc3545;
    --border-hover: 1px solid #dc3545 !important;
    --border-focus: 1px solid #dc3545 !important;
    &.n-input--focus {
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 10%);
    }
}

.n-icon {
    line-height: 0.8rem;
}

.invalid-feedback {
    color: #cc2c2c;
}

.valid-feedback {
    color: #32d885;
}

.invalid-feedback,
.valid-feedback {
    font-size: 94%;
}

.is-invalid ~ .invalid-feedback,
.is-invalid-parent ~ .invalid-feedback {
    display: block;
}
</style>
